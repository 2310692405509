import axios from './AxiosInterceptors';
import store from '../store/Store';


const ROOT_URL = `https://registryapi-staging.financialdataexchange.org/fdxregistryapi`;


export default {
    ROOT_URL,
getEnumTypes: function () {
        return axios.get(`${ROOT_URL}/util/enumtypes`);
    },
    getCountries: function () {
        return axios.get(`${ROOT_URL}/util/countries`);
    },
    getListingTags: function () {
        return axios.get(`${ROOT_URL}/util/listingTags`);
    },
    requestCompanyData: function (request) {
        return axios.post(`${ROOT_URL}/request/company-data-request`, request);
    },
    requestRegistryEntry: function (request) {
        return axios.post(`${ROOT_URL}/request/registry-entry`, request);
    },
    requestChange: function (request) {
        return axios.post(`${ROOT_URL}/request/change-request`, request);
    },
    getOrganizations: function () {
        return axios.get(`${ROOT_URL}/organizations`);
    },
    getOrganizationDetail: function (id) {
        return axios.get(`${ROOT_URL}/organizations/get/${id}`);
    },
    createOrganization: function (request) {
        return axios.post(`${ROOT_URL}/organizations`, request);
    },
    publishOrganization: function (request) {
        return axios.get(`${ROOT_URL}/organizations/${request.id}/version-history/${request.vid}/publish`);
    },
    companyDelete: function (id) {
        return axios.delete(`${ROOT_URL}/organizations/delete/${id}`);
    },
    createVersionHistory: function (request) {
        return axios.post(`${ROOT_URL}/organizations/${request.id}/version-history`, request.request);
    },
    getVersionHistoryList: function (id) {
        return axios.get(`${ROOT_URL}/organizations/${id}/version-history`);
    },
    getVersionHistoryById: function (request) {
        return axios.get(`${ROOT_URL}/organizations/${request.id}/version-history/${request.versionId}`);
    },
    updateOrganizationByVersionHistory: function (request) {
        return axios.put(`${ROOT_URL}/organizations/update/${request.id}/version-history/${request.versionHistoryId}`, request.request);
    },
    restoreVersionHistory: function (request) {
        return axios.get(`${ROOT_URL}/organizations/${request.orgid}/version-history/${request.versionid}/restore`);
    },
    makeInactive: function (id) {
        return axios.get(`${ROOT_URL}/organizations/${id}/status/markinactive`);
    },
    searchImis: function (imisNumer) {
        return axios.get(`${ROOT_URL}/organizations/imis/${imisNumer}`);
    },
    ssoAuthentication: function () {
        window.location.href =  ROOT_URL+'/oauth2/authorization/fdx-openId';
    },
    ssoSignout: function (token) {
        var jwt = JSON.parse(token).id_token;
       return axios.get(`${ROOT_URL}/auth/signOut?idToken=${jwt}`);
    },
    getAccessToken: function (authCode) {
        return axios.get(`${ROOT_URL}/auth/token/${authCode}`);
    },
    refreshToken: function () {
        return axios.get(`${ROOT_URL}/auth/refreshtoken/`);
    },
    getUserInfo: function () {
        return axios.get(`${ROOT_URL}/auth/userinfo`);
    },
    uploadImage: function (request) {
        return axios.post(`${ROOT_URL}/organizations/logo/upload`, request, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        });
    },
    getAPIVersions: function (request) {
        return axios.get(`${ROOT_URL}/product/apiversion/apitype/${request}`);
    },
    getTaxonomyTypes: function () {
        return axios.get(`${ROOT_URL}/organizations/types/fdxtaxonomy`);
    },
    getOrganizationsList: function (request) {
        return axios.post(`${ROOT_URL}/organizations/search/listingTags`,request.request,request.user);
    },
}
